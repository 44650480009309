import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 gx-xxl-8" }
const _hoisted_2 = { class: "col-xxl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GridEvents = _resolveComponent("GridEvents")!
  const _component_NMEventDetailsModal = _resolveComponent("NMEventDetailsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_GridEvents, {
          "widget-classes": "card-xxl-stretch mb-5 mb-xxl-8",
          events: _ctx.events,
          isLoading: _ctx.isLoading,
          eventsMeta: _ctx.eventsMeta,
          gotoPage: _ctx.gotoPage
        }, null, 8, ["events", "isLoading", "eventsMeta", "gotoPage"])
      ])
    ]),
    _createVNode(_component_NMEventDetailsModal, { event: _ctx.event }, null, 8, ["event"])
  ], 64))
}