
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions, Modules } from "@/store/enums/StoreEnums";
import { hideModal } from "@/core/helpers/dom";
import { formatDateTime } from "@/core/helpers/date";

export default defineComponent({
  name: "EventDetailsModal",
  components: {},
  setup() {
    const store = useStore();

    const modalRef = ref<null | HTMLElement>(null);

    let eventDetails = computed(() => {
      return store.getters[Modules.EVENT + "getEventDetails"];
    });

    // hideModal(modalRef.value);

    return { modalRef, formatDateTime, eventDetails };
  },
});
